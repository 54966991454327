export async function retry<T>(action: () => Promise<T>, times = 1): Promise<T> {
  for (;;) {
    try {
      return await action();
    } catch (e) {
      if (--times < 0) {
        throw e;
      }
    }
  }
}
